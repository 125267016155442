import { AlertBox, Loading, Stack } from '@guider-global/ui';

import {
  useOrganization,
  useSanityOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import { useAdminUserProfiles, useProfileProgramOverviews } from 'hooks';

import { getSubDomain } from '@guider-global/front-end-utils';
import {
  GroupProgramMembershipsContainer,
  IndividualProgramMembershipsContainer,
} from 'containers';
import { useEffect, useMemo } from 'react';

export type ProfileProgramOverviewListContainerProps = {
  profileId: string;
};

export function ProfileProgramOverviewListContainer({
  profileId,
}: ProfileProgramOverviewListContainerProps) {
  const { profileProgramOverviews, areProfileProgramOverviewsFetching } =
    useProfileProgramOverviews();

  const organizationSlug = getSubDomain();
  const { organization } = useOrganization({ organizationSlug });
  const primaryProgramSlug =
    organization?.programs?.primary_program?.program_slug;
  // Profiles

  const { adminUserProfiles, isLoadingAdminUserProfiles } =
    useAdminUserProfiles({});

  const userProfile = adminUserProfiles().at(0);
  const userEmail = userProfile?.email ?? 'N/A';
  const userDisplayName = userProfile?.displayName ?? 'N/A';
  const userOrgSlug = userProfile?.organizationSlug ?? 'N/A';

  const userData = useMemo(
    () => ({
      userEmail,
      userDisplayName,
      userProfileId: profileId,
      userOrgSlug,
    }),
    [userEmail, userDisplayName, profileId, userOrgSlug],
  );

  // Programs
  const { getPrograms, refetchPrograms, isLoadingSanityPrograms } =
    useSanityOrganizationPrograms({
      getSilently: true,
      organizationSlug,
    });

  const programs = getPrograms()!;
  const programsOrgSlug =
    programs[0]?.metadata?.organization?.basic_info?.subdomain?.current;

  useEffect(() => {
    if (userOrgSlug !== programsOrgSlug) {
      refetchPrograms(userOrgSlug, 'en_GB');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programsOrgSlug, userOrgSlug]);

  const programData = useMemo(() => {
    const filteredPrograms = programs
      .filter((program) =>
        profileProgramOverviews.some(
          (overview) => overview.programSlug === program.metadata.id.current,
        ),
      )
      .map((program) => ({
        program,
        variation: program.program_details?.program_variation,
        overview: profileProgramOverviews.find(
          (overview) => overview.programSlug === program.metadata.id.current,
        ),
      }))
      .sort((a, b) => {
        if (a.program.metadata.id.current === primaryProgramSlug) return -1;
        if (b.program.metadata.id.current === primaryProgramSlug) return 1;
        return 0;
      });

    return filteredPrograms;
  }, [programs, primaryProgramSlug, profileProgramOverviews]);

  const programDataWithOverviews = useMemo(
    () => programData.filter(({ overview }) => overview),
    [programData],
  );

  if (
    areProfileProgramOverviewsFetching ||
    isLoadingAdminUserProfiles() ||
    isLoadingSanityPrograms()
  ) {
    return <Loading sx={{ mt: '10vh', mb: '50vh' }} />;
  } else {
    if (programDataWithOverviews.length === 0) {
      return (
        <AlertBox
          iconVariant="warning"
          variant="iconWithBackground"
          title={'No program memberships'}
          description={'This user is not a member of any programs yet'}
          sx={{ my: '50px' }}
        />
      );
    }
  }

  return (
    <Stack direction="column" spacing={2}>
      {programData.map(({ program, variation, overview }, index) => {
        if (!overview) {
          return null;
        } else {
          if (variation === 'group') {
            return (
              <GroupProgramMembershipsContainer
                profileProgramOverview={overview}
                program={program}
                key={program._id || `group-${index}`}
                userData={userData}
              />
            );
          } else if (variation === 'individual') {
            return (
              <IndividualProgramMembershipsContainer
                profileProgramOverview={overview}
                program={program}
                key={program._id || `individual-${index}`}
                userData={userData}
              />
            );
          } else {
            return null;
          }
        }
      })}
    </Stack>
  );
}
